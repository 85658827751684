<template>
  <div style="width: 100%; height: 100%;">
    <ag-grid-vue
      style="width: 100%; height: calc(100vh - 200px);"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowGroupPanelShow="'always'"
      :serverSideSortAllLevels="true"
      :serverSideOnlyRefreshFilteredGroups="true"
      :purgeClosedRowNodes="true"
      :serverSideInitialRowCount="10"
      :getServerSideGroupLevelParams="getServerSideGroupLevelParams"
      :rowModelType="rowModelType"
      :cacheBlockSize="cacheBlockSize"
      :maxBlocksInCache="maxBlocksInCache"
      :autoGroupColumnDef="autoGroupColumnDef"
      :animateRows="true"
      :sideBar="sidebar"
      @grid-ready="onGridReady"
      @filter-changed="onFilterChanged"
    ></ag-grid-vue>
  </div>
</template>


<script>
import { AgGridVue } from "ag-grid-vue"
import 'ag-grid-enterprise';

export default {
  components: {
    AgGridVue

  },
  data: () => ({
    defaultColDef: {
        flex: 1,
        minWidth: 100,
        resizable: true,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        // suppressSizeToFit: true,
      },

      sidebar: {
        toolPanels: ["columns", "filters"],
        defaultToolPanel: "columns",
        collapsed: true
        
      },

      sortModel: [],
      filterModel: {},
      rowBuffer: 0,
      cacheBlockSize: 50,
      rowModelType: "serverSide",
      maxBlocksInCache: 2,
      gridApi: null,

      autoGroupColumnDef: {
      headerName: 'Group',
      minWidth: 250,
      field: 'groupKey',
      cellRendererParams: {
        innerRenderer: (params) => {
          if (params.node.group) {
            // This is the product name or commodityCode for the group row
            const groupKey = params.node.key || 'Empty';

            // This is the count of the grouped records
            const count = params.node.allChildrenCount || params.data?.count || 0;

            // Return the group name (product name) and the count in brackets
            return `${groupKey} (${count})`;
          }
          return params.value;
        }
      }
    },

      columnDefs: [
      { headerName: 'Status', field: 'status', enableRowGroup: true, width: 100, rowGroup: true, hide: true,  sortable: 'true', filter: 'agTextColumnFilter' },

      { headerName: 'PUC', field: 'puc', enableRowGroup: true,  sortable: 'true',  width: 100, filter: 'agTextColumnFilter'  },
      { headerName: 'PHC', field: 'phc', enableRowGroup: true,  sortable: 'true',  width: 100,  filter: 'agTextColumnFilter' },
      { headerName: 'Location', field: 'locationCode', enableRowGroup: true,  sortable: 'true',   filter: 'agTextColumnFilter' },

      { headerName: 'Product', field: 'commodityCode', enableRowGroup: true, width: 100, sortable: 'true',  filter: 'agTextColumnFilter',  
      
      valueGetter: (params) => {
        // Use product.name if available, otherwise fallback to commodityCode
        return params.data.product && params.data.product.name
          ? params.data.product.name
          : params.data.commodityCode;
      },
      keyCreator: (params) => {
        // Use product.name if available, otherwise fallback to commodityCode for grouping
        return params.data.product && params.data.product.name
          ? params.data.product.name
          : params.data.commodityCode;
      }
      },

      { headerName: 'Variety', field: 'varietyCode', enableRowGroup: true, width: 100, sortable: 'true',  filter: 'agTextColumnFilter'  },
      { headerName: 'Brand/Mark', field: 'mark', enableRowGroup: true, sortable: 'true',  width: 100, filter: 'agTextColumnFilter' },
      { headerName: 'Inventory Code', field: 'inventoryCode', enableRowGroup: true,  sortable: 'true',  width: 100, filter: 'agTextColumnFilter'  },
      { headerName: 'Grade/Class', field: 'grade', enableRowGroup: true, sortable: 'true',  width: 100, filter: 'agNumberColumnFilter'  },
      { headerName: 'Pack Code', field: 'packCode', enableRowGroup: true, sortable: 'true',  width: 100, filter: 'agTextColumnFilter' },
      { headerName: 'Count/Size', field: 'countSize', enableRowGroup: true, sortable: 'true',  width: 100, filter: 'agNumberColumnFilter' },
      { headerName: 'No. Pallets', field: 'palletQuantity', enableRowGroup: true, sortable: 'true',  width: 100, filter: 'agNumberColumnFilter'  },
      { headerName: 'No. Cartons', field: 'cartonQuantity', enableRowGroup: true, sortable: 'true',  width: 100, filter: 'agNumberColumnFilter'  },
    ],


    groupRowRendererParams: {
      innerRenderer: (params) => {
        if (params.node.group) {
          // Access groupKey (such as status or other grouping columns)
          const groupKey = params.node.key || 'No Status';

          // Access count from the data (make sure the backend includes this count in response)
          const count = params.node.allChildrenCount || params.data?.count || 0;

          // Return the formatted string with the count
          return `${groupKey} (${count})`;
        }
        return params.value;
      }
    },

  }),

  async created() {

    this.rowBuffer = 0;
    this.rowModelType = "serverSide";
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;

  },
  methods: {
  onGridReady(params) {
  this.gridApi = params.api;

  const datasource = {
    getRows: async (params) => {
      try {
        const requestParams = {
          startRow: params.request.startRow,
          endRow: params.request.endRow,
          sortModel: JSON.stringify(params.request.sortModel || []),
          filterModel: JSON.stringify(params.request.filterModel || {}),
          rowGroupCols: JSON.stringify(params.request.rowGroupCols || []),
          groupKeys: JSON.stringify(params.request.groupKeys || []),
        };

        // Fetch the stock overview data
        const stockResponse = await this.$API.getActiveStockOverview(requestParams);

        if (stockResponse && stockResponse.data) {
          console.log('Stock Overview Data:', stockResponse.data);

          // Process the data to include the count from the backend
          const rowData = stockResponse.data.map((row) => {
            return {
              ...row,
              groupKey: row.groupKey, // Status (or other group columns)
              count: row.count // Count provided by backend
            };
          });

          // Return success response to AG Grid
          params.success({
            rowData,
            rowCount: stockResponse.lastRow
          });
        } else {
          console.error("Failed to load data");
          params.fail();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        params.fail();
      }
    }
  };

  // Register the datasource with the grid
  this.gridApi.updateGridOptions({ serverSideDatasource: datasource });
},


  onSortChanged() {
      // Trigger the data source to reload based on new sort configuration
      this.gridApi.refreshServerSide({ purge: true });
    },


    onFilterChanged() {
      // When filter changes, reload the server-side store
      this.gridApi.refreshServerSide({ purge: true });
    },


  getServerSideGroupLevelParams(params) {
    return params.level === 0
      ? { cacheBlockSize: 100 } // Root level
      : { cacheBlockSize: 50 };  // Child level
  },


populateFilterValues(filters) {
  this.gridApi.getColumns().forEach(col => { 
    const field = col.getColDef().field;
    if (filters[field]) {
      col.getColDef().filterParams = {
        values: filters[field], // Populate unique filter values
      };
    }
  });
  this.gridApi.refreshHeader();
},


getRowId(params) {
  // Ensure each row has a unique ID
  if (params.data.groupKey) {
    return `group-${params.data.groupKey}`;  // Use groupKey for grouped rows
  } else if (params.data.id) {
    return `row-${params.data.id}`;  // Use unique ID for row-level data
  } else {
    return null;  // Fallback in case ID is missing, though this shouldn't happen
  }
},

  },
};
</script>